import { useEffect } from 'react';
import { Layout } from '../../shared/layout/Layout';
import cls from './politics.module.scss';

export const Politics = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    return (
        <Layout>
            <div className={cls.politics}>
                <h2 className={cls.politics__title}>Политика конфиденциальности</h2>
                <h3 className={cls.politics__subtitle}>ОБЯЗАТЕЛЬСТВА КОМПАНИИ</h3>
                <p className={cls.politics__text}>
                    Консалтинговая компания Квант Шоп (далее – Компания) считает своим долгом
                    обеспечивать безопасность и конфиденциальность всех личных сведений, получаемых
                    от Клиентов. Для нас важны сохранность и неприкосновенность персональных данных
                    наших настоящих и будущих Клиентов и иных посетителей сайта. Настоящая Политика
                    конфиденциальности (далее – Политика) составлена, чтобы объяснить, как Компания
                    собирает, использует и защищает персональную информацию.
                </p>
                <h3 className={cls.politics__subtitle}>
                    ЗАЧЕМ МЫ ПОЛУЧАЕМ ОТ ВАС ПЕРСОНАЛЬНЫЕ ДАННЫЕ
                </h3>
                <p className={cls.politics__text}>
                    Компании необходимо получить персональные данные о Клиенте для повышения
                    качества консультирования наших Клиентов по всем возникающим вопросам. Эти
                    данные обеспечат понимание Ваших потребностей и помогут нам предоставить Вам
                    информацию о продуктах и услугах, максимально подходящих и удобных для Вас.
                </p>
                <h3 className={cls.politics__subtitle}>ПЕРСОНАЛЬНЫЕ ДАННЫЕ МОГУТ ВКЛЮЧАТЬ:</h3>
                <p className={cls.politics__text}>
                    1. Личную информацию, которую Вы указываете в анкетах и формах: Ваши Ф.И.О,
                    адрес проживания, дату рождения, паспортные данные, идентификационный номер,
                    личный номер мобильного телефона, род занятий, должность и другие;
                    <br />
                    2. Финансовую информацию: например, такую, как Ваш инвестиционный опыт.
                </p>
                <h3 className={cls.politics__subtitle}>ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОМОГАЮТ НАМ:</h3>
                <p className={cls.politics__text}>
                    1. Подтвердить Вашу личность;
                    <br />
                    2. Своевременно информировать Вас о расширении перечня предоставляемых Компанией
                    услуг и сервисов, которые, по нашему мнению, могут заинтересовать Вас;
                    <br />
                    3. Анализировать статистические данные, чтобы предложить Вам продукты и услуги
                    более высокого качества.
                </p>
                <h3 className={cls.politics__subtitle}>COOKIES</h3>
                <p className={cls.politics__text}>
                    Cookies - небольшой фрагмент данных, отправляемый веб-сервером для хранения на
                    компьютере пользователя в виде файла, чтобы при следующем Вашем обращении
                    веб-сайт мог извлечь эту информацию. Этот файл каждый раз пересылается
                    веб-серверу в соответствующем запросе при попытке открыть страницу сайта.
                    Cookies могут использоваться на некоторых страницах нашего сайта, чтобы
                    предоставить Вам более быстрый и удобный доступ. Если Вы не желаете получать
                    Cookies, то большинство веб-браузеров разрешают Вам их отклонять, в то же время
                    позволяя Вам посещать наш сайт без каких-либо ограничений. Cookies не
                    используются для идентификации посетителей нашего сайта.
                </p>
                <h3 className={cls.politics__subtitle}>ТРЕТЬИ ЛИЦА</h3>
                <p className={cls.politics__text}>
                    Компания вправе передавать Вашу личную информацию дочерним /аффилированным
                    организациям, банкам, аудиторам, консультантам и агентам Компании для
                    осуществления контроля за деятельностью Компании или привлечения данных лиц к
                    процессу оказания Компанией услуг своим клиентам. Компания гарантирует соблюдение
                    настоящей Политики Доверенными лицами и принятие указанными лицами необходимых
                    мер для защиты конфиденциальной информации Клиентов Компании. Компания также
                    имеет право предоставить конфиденциальную информацию о персональных данных
                    Клиента третьим лицам, которые не являются Доверенными лицами, в случае
                    получения официального запроса (требования, распоряжения) административных и/или
                    правоохранительных органов, судов.
                </p>
                <h3 className={cls.politics__subtitle}>ИСПОЛЬЗОВАНИЕ ИНФОРМАЦИИ</h3>
                <p className={cls.politics__text}>
                    Предоставляя Компании персональные данные, Вы даете согласие на их
                    использование, а также на их сбор, обработку, запись, систематизацию, накопление,
                    хранение, уточнение (обновление, изменение), извлечение, использование, передачу
                    (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
                    уничтожение любой информации, относящейся прямо или косвенно к Вам.
                </p>
                <h3 className={cls.politics__subtitle}>БЕЗОПАСНОСТЬ И ЗАЩИТА</h3>
                <p className={cls.politics__text}>
                    Компания предельно серьезно подходит к вопросам обеспечения безопасности
                    персональных данных Клиента. Компания осуществляет все необходимые действия для
                    защиты Ваших конфиденциальных данных, включая поддержание строгих стандартов
                    безопасности при обмене конфиденциальной информацией внутри организации и
                    использование ведущих технологий безопасности хранения данных. Для исключения
                    возможности недобросовестного использования платежных данных они передаются
                    Компании в сокращенном варианте через защищенное соединение.
                </p>
                <h3 className={cls.politics__subtitle}>КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>
                <p className={cls.politics__text}>
                    Если у Вас возникли какие-то вопросы, связанные с настоящей Политикой, мы будем
                    рады ответить на них по e-mail: <a href="mailto: info@kvant.shop">info@kvant.shop</a>
                </p>
            </div>
        </Layout>
    );
};
