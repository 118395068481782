import { useEffect } from 'react';
import { Layout } from '../../shared/layout/Layout';
import cls from './risk.module.scss';

export const Risk = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    return (
        <Layout>
            <div className={cls.risk}>
                <h2 className={cls.risk__title}>Уведомление о рисках</h2>
                <p className={cls.risk__text}>
                    Настоящее уведомление о рисках (далее – Уведомление) разработано компанией ООО
                    "Квант Шоп" и направлено на информирование всех наших клиентов (действующих и
                    возможных), а также наших потенциальных партнеров о рисках, связанных с
                    совершением операций с беспоставочными внебиржевыми финансовыми инструментами на
                    финансовых рынках, и возможных финансовых потерях, возникающих в связи с данными
                    рисками.
                </p>
                <h3 className={cls.risk__subtitle}>ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                <p className={cls.risk__text}>
                    1.1. Операция с беспоставочным внебиржевым финансовым инструментом на финансовых
                    рынках (далее – Операция) является внебиржевой сделкой, состоит из двух частей –
                    открытие и закрытие позиции, не предусматривает прав и не устанавливает
                    обязанностей сторон по покупке (продаже) базового актива, но создает обязанность
                    уплатить (право получить) сумму разницы между ценой базового актива в момент
                    открытия позиции и ценой базового актива в момент закрытия позиции.
                </p>
                <p className={cls.risk__text}>
                    1.2 Все клиенты и партнеры компании ООО "Квант Шоп" (далее – Клиенты) в
                    обязательном порядке перед непосредственным совершением Операций с
                    использованием программных продуктов ООО "Квант Шоп" должны внимательно
                    ознакомиться с настоящим Уведомлением. Тем не менее, следует отметить, что
                    данное Уведомление не может полностью учесть и соответственно не раскрывает все
                    возможные риски и другие важные аспекты, связанные с совершением Операций.
                    Уведомление о рисках было разработано, чтобы объяснить в общих чертах типы и
                    характер рисков, возникающих при совершении Операций.
                </p>
                <p className={cls.risk__text}>
                    1.3. Путем размещения настоящего Уведомления в соответствующих программных
                    продуктах, устанавливаемых и используемых Клиентами, компания ООО "Квант Шоп"
                    выполняет свое обязательство перед Клиентами относительно их надлежащего
                    информирования о возможных рисках, связанных с совершением ими Операций на
                    финансовых рынках посредством программно-технических комплексов (программных
                    продуктов), предоставляемых компанией ООО "Квант Шоп" и третьими лицами, и о
                    возможных финансовых потерях, возникающих в связи с данными рисками.
                </p>
                <h3 className={cls.risk__subtitle}>
                    ОПРЕДЕЛЕНИЕ РИСКА И ПОДХОДОВ К СОВЕРШЕНИЮ ОПЕРАЦИЙ
                </h3>
                <p className={cls.risk__text}>
                    2.1. Под риском в настоящем Уведомлении подразумевается возможность
                    возникновения потерь в полном размере внесенных Клиентами денежных средств для
                    совершения Операций (маржинального обеспечения), дополнительных затрат и
                    неполучения запланированных доходов при совершении Клиентом Операций.
                </p>
                <p className={cls.risk__text}>
                    2.2. Совершение Операций на финансовых рынках осуществляется с использованием
                    маржинального (кредитного) плеча. Это означает, что объем совершаемых Клиентом
                    Операций на финансовых рынках может в десятки или сотни раз превышать размер
                    внесенного им маржинального обеспечения. Таким образом, маржинальное (кредитное)
                    плечо увеличивает для Клиента возможность получения прибыли в десятки или сотни
                    раз, но при этом, в таком же объеме, увеличивает и его риски получения убытков.
                    При использовании маржинального (кредитного) плеча даже сравнительно небольшое
                    изменение цены финансового инструмента (базового актива), по которому Клиент
                    совершает Операцию, может привести к возникновению у него значительных потерь
                    (убытков).{' '}
                </p>
                <p className={cls.risk__text}>
                    2.3. Совершение Операций на финансовых рынках позволяет Клиенту получать высокую
                    прибыль, однако, в то же время, несет в себе потенциально высокий уровень риска
                    потерь (убытков). Поэтому, в первую очередь Клиенту важно тщательно проработать
                    стратегию совершения Операций и определить объем свободных финансовых ресурсов,
                    потеря которых не отразится значительным образом на его благосостоянии. Не
                    рекомендуется использовать в качестве маржинального обеспечения денежные
                    средства, которые являются заемными, получены в форме кредита, социального
                    пособия или пенсии.{' '}
                </p>
                <p className={cls.risk__text}>
                    2.4. Совершение Операций на финансовых рынках является высоко рисковым и
                    спекулятивным видом деятельности и не подходит для всех категорий Клиентов.{' '}
                </p>
                <h3 className={cls.risk__subtitle}>НАЛОГИ</h3>
                <p className={cls.risk__text}>
                    3.1. Доходы, полученные на финансовых рынках, могут являться или стать в будущем
                    объектом налогообложения, в том числе из-за изменений в законодательстве страны
                    проживания Клиента или его личных обстоятельств. Клиент самостоятельно несет
                    ответственность за уплату любых налогов (сборов, пошлин), которые могут ему
                    начисляться в отношении доходов, полученных вследствие совершения им Операций.
                    Компания ООО "Квант Шоп" не предоставляет налоговые консультации.
                </p>
                <h3 className={cls.risk__subtitle}>
                    ПЕРЕЧЕНЬ ВОЗМОЖНЫХ РИСКОВ. РАЗГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ
                </h3>
                <p className={cls.risk__text}>
                    4.1. Рыночный риск – риск возникновения потерь (убытков), неполучения
                    запланированных доходов вследствие исполнения распоряжений Клиента о фиксации
                    цены базового актива при совершении Операций по котировкам, существенно
                    отличающимся от тех, которые были указаны Клиентом. Распоряжения (ордера),
                    представляемые Клиентом в целях ограничения убытков (ордер «Стоп Лосс»), либо
                    фиксации определенной прибыли (ордер «Тейк Профит»), также могут быть исполнены
                    по иным котировкам, чем те, которые указаны в данных ордерах.
                </p>
                <p className={cls.risk__text}>
                    Рыночный риск связан с большими объемами капиталов, которые присутствуют на
                    мировых финансовых рынках, а также большим количеством участников, вследствие
                    чего имеется вероятность быстрого изменения цены базового актива, что вызывает
                    невозможность исполнения распоряжений Клиента по указанным им ценам.
                </p>
                <p className={cls.risk__text}>
                    Изменения цен базовых активов в течение операционного дня могут достигать
                    значительных величин, что может привести к дополнительным финансовым потерям для
                    Клиента. Причинами высокой скорости изменения цен базовых активов являются
                    разного рода политические, экономические, финансовые события в мире, а также
                    изменения конъюнктуры рынка.
                </p>
                <p className={cls.risk__text}>
                    Результаты ваших Операций в прошлом никак не связаны с возможными результатами в
                    будущем и не могут гарантировать безусловное получение дохода по новым
                    операциям.
                </p>
                <p className={cls.risk__text}>
                    Клиент обязуется самостоятельно вводить всю необходимую информацию для установки
                    и конфигурации программных продуктов, предоставляемых компанией ООО "Квант
                    Шоп", самостоятельно определять персональные требования к настройкам
                    дополнительных параметров, в том числе по размеру риска и допустимых потерь от
                    совершения Операций. При неуказании персональных требований по уровням размера
                    риска и допустимых потерь от совершения Операций данные параметры
                    устанавливаются по уровням по умолчанию согласно конфигурации программных
                    продуктов, при этом размер допустимых потерь признается 100%, что означает
                    возможность потери всех средств, внесенных пользователем торговой платформы
                    MetaTrader5 для совершения операций на финансовых рынках.
                </p>
                <p className={cls.risk__text}>
                    Компания ООО "Квант Шоп" ни в коей мере не гарантирует получение Клиентами
                    прибыли при совершении операций с использованием предоставляемых им программных
                    продуктов. Все Операции на финансовых рынках совершаются Клиентами под их
                    собственную имущественную ответственность и риск получения убытков, в том числе
                    полной потери всех внесенных денежных средств.
                </p>
                <p className={cls.risk__text}>
                    4.2. Риск ликвидности – риск возникновения потерь (убытков), дополнительных
                    затрат, неполучения запланированных доходов вследствие возникновения ситуации,
                    при которой открытие или закрытие позиции (иными словами покупка или продажа
                    базового актива) в рамках каждой отдельной Операции будет затруднительным в
                    определенный момент времени.
                </p>
                <p className={cls.risk__text}>
                    Клиенту следует обращать внимание на ликвидность соответствующих базовых
                    активов, так как при низкой ликвидности невозможность закрытия позиций может
                    привести к значительным потерям (убыткам) Клиента. Низкая ликвидность базового
                    актива или ее отсутствие в определенный момент времени может приводить к
                    увеличению спрэда. Большой спрэд затрудняет исполнение распоряжений,
                    направляемых Клиентом в целях ограничения убытков (ордер «Стоп Лосс»). Во
                    избежание возникновения убытков Клиент должен самостоятельно отслеживать
                    ситуацию на рынке и в зависимости от нее совершать Операции.
                </p>
                <p className={cls.risk__text}>
                    В определенных случаях у брокера, с которой сотрудничает Клиент, может не быть
                    возможности исполнить распоряжение Клиента о фиксации цены базового актива,
                    например, но не только, в следующих случаях: в течение выхода финансовых или
                    иных новостей; открытия или закрытия торговых сессий; высокой волатильности
                    рынка, при которой цены могут значительно изменяться в сторону как повышения,
                    так и понижения, не попадая в заявленные Клиентом значения; быстрой динамики
                    изменения цены; недостаточной ликвидности на рынке; при форс-мажорных
                    обстоятельствах. Если в подобных случаях у брокера нет возможности исполнить
                    распоряжение Клиента относительно цены базового актива, размера позиции или по
                    другой причине, такое распоряжение не будет исполнено, и Клиент будет
                    самостоятельно нести риск получения убытков, связанных с невозможностью фиксации
                    цены базового актива.
                </p>
                <p className={cls.risk__text}>
                    4.3. Технический риск – риск возникновения потерь (убытков), дополнительных
                    затрат, неполучения запланированных доходов вследствие выхода из строя
                    (поломки), неисправности, сбоев в работе, отключения (в том числе при наличии
                    злоумышленных действий, хакерских атак) линий и каналов связи, Интернета,
                    электричества, информационных, технических, программных, других средств и
                    систем, используемых Клиентом при совершении Операций, а также неисправностей
                    (либо невозможности использования Клиентом той или иной функции
                    программно-технического средства), возникших по причине незнания инструкций,
                    либо несоблюдения Клиентом правил использования программно-технических средств
                    либо правил эксплуатации оборудования, используемого Клиентом для совершения
                    Операций.
                </p>
                <p className={cls.risk__text}>
                    Во время максимальных нагрузок на рынке (например, во время выхода экономических
                    новостей), Клиент должен осознавать потенциальную возможность высоких нагрузок
                    на каналы связи, и, соответственно, временную невозможность связаться с сервером
                    брокера, с которой он сотрудничает.
                </p>
                <p className={cls.risk__text}>
                    С учетом того, что предоставляемые компанией ООО "Квант Шоп" программные
                    продукты работают с торговой платформой MetaTrader5, являющейся отдельным
                    программным продуктом, разработкой и конфигурацией которого занимается третье
                    лицо, компания ООО "Квант Шоп" не может гарантировать полную
                    работоспособность своих программных продуктов и их 100% отказоустойчивость. В
                    связи с этим компания ООО "Квант Шоп" заявляет, а Клиент соглашается с тем,
                    что в случае неработоспособности какого-либо программного продукта и (или) его
                    некорректной работы компания ООО "Квант Шоп" не будет нести ответственность
                    за причиненные убытки Клиенту возможными сбоями в работе программных продуктов.
                    При этом компания ООО "Квант Шоп" при содействии Клиентов будет собирать всю
                    информацию относительно причин сбоев и в силу технических возможностей принимать
                    меры по их устранению и недопущению в дальнейшем.
                </p>
                <p className={cls.risk__text}>
                    В случае изменения брокером, к серверу которого подключена торговая платформа
                    MetaTrader5, маржинального (кредитного) плеча либо внесения иных изменений в
                    спецификации торгуемых финансовых инструментов по сравнению с теми, которые
                    учитывались при настройке программных продуктов либо брались в расчет при
                    открытии программным продуктом позиции по какому-либо финансовому инструменту, а
                    также в случае снижения баланса (средств, уровня маржи либо иного финансового
                    расчетного показателя) на торговом аккаунте пользователя MetaTrader5 до
                    установленного брокером уровня, может произойти принудительное закрытие позиции
                    с отрицательным финансовым результатом либо возникнуть иная ситуация, приведшая
                    к финансовым потерям (убыткам). Компания ООО "Квант Шоп" никоим образом не
                    отвечает за данные убытки, так как они вызваны действиями третьих лиц, которые
                    ему не подконтрольны.
                </p>
                <p className={cls.risk__text}>
                    Компания ООО "Квант Шоп" не несет ответственности за случаи
                    неработоспособности программных продуктов и вследствие этого причиненные Клиенту
                    или третьим лицам убытки, если они стали следствием хакерских атак, аварий
                    (сбоев в работе) компьютерных сетей, силовых электрических сетей или систем
                    электросвязи, непосредственно используемых для работы программных продуктов и
                    (или) торговой платформы MetaTrader5, отказа коммуникационного оборудования,
                    отсоединения пользователя торговой платформы MetaTrader5 от торгового сервера,
                    помех или задержек при совершении операций на финансовых рынках через глобальную
                    компьютерную сеть Интернет.{' '}
                </p>
                <p className={cls.risk__text}>
                    4.4. Валютный риск – риск возникновения потерь (убытков), неполучения
                    запланированных доходов вследствие изменения курсов валют на международном
                    валютном рынке при необходимости конвертации любых сумм (маржинального
                    обеспечения, прибыли, убытка и т.д.) в валюту аккаунта, созданного брокером
                    Клиенту, или в валюту банковского счёта Клиента (при возврате маржинального
                    обеспечения Клиенту).
                </p>
                <p className={cls.risk__text}>
                    4.5. Риск потери конфиденциальности – риск возникновения потерь (убытков),
                    дополнительных затрат, неполучения запланированных доходов вследствие
                    несанкционированного доступа третьих лиц к конфиденциальной информации Клиента и
                    использование ее в своих целях. В данном случае под конфиденциальной информацией
                    имеются ввиду:
                </p>
                <p className={cls.risk__text}>
                    Клиент обязан принять исчерпывающие меры для защиты и сохранности своей
                    конфиденциальной информации.
                </p>
                <p className={cls.risk__text}>
                    Информация, пересылаемая в зашифрованном виде посредством электронной почты не
                    защищена от несанкционированного доступа третьих лиц. Все финансовые потери,
                    вызванные данным фактом, полностью ложатся на Клиента.
                </p>
                <p className={cls.risk__text}>
                    4.6. Риск коммуникации – риск возникновения потерь (убытков), дополнительных
                    затрат, неполучения запланированных доходов вследствие несвоевременного
                    получения или не получения Клиентом сообщений от брокера, к серверам которой
                    подключен торговый терминал MetaTrader5, либо их несвоевременного прочтения или
                    непрочтения.
                </p>
                <p className={cls.risk__text}>
                    4.7. Правовой риск – риск возникновения потерь (убытков), дополнительных затрат,
                    неполучения запланированных доходов вследствие изменения законодательства, в том
                    числе налогового, страны регистрации (проживания) Клиента и (или) страны
                    совершения Операций.
                </p>
                <p className={cls.risk__text}>
                    4.8. Социально-политический риск – риск возникновения потерь (убытков),
                    дополнительных затрат, неполучения запланированных доходов вследствие значимых
                    изменений политической (в том числе по причинам смены органов власти /
                    управления) и экономической ситуации в стране, повлекших за собой социальную
                    нестабильность и (или) экономический кризис.
                </p>
                <p className={cls.risk__text}>
                    4.9. Риск банкротства брокера – риск возникновения потерь (убытков),
                    дополнительных затрат, неполучения запланированных доходов вследствие
                    наступления неплатежеспособности брокера, с которой сотрудничает Клиент.
                </p>
                <p className={cls.risk__text}>
                    4.10. Форс-мажорные обстоятельства. Компания ООО "Квант Шоп" не несет
                    ответственности за неисполнение (ненадлежащее исполнение) своих обязательств по
                    заключенным с Клиентом соглашениям, если этому препятствовали обстоятельства
                    непреодолимой силы (форс-мажорные обстоятельства), под которыми понимаются любое
                    действие, событие или явление вне воли стороны, то есть которые сторона не могла
                    предвидеть или наступление которого не могла предотвратить, в том числе, но не
                    ограничиваясь: забастовки, массовые беспорядки или гражданские волнения,
                    террористические акты, войны, стихийные бедствия, аварии, пожары, наводнения,
                    штормы, ураганы, ddos-aтаки, перебои электроснабжения.
                </p>
                <h3 className={cls.risk__subtitle}>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
                <p className={cls.risk__text}>
                    5.1. Целью настоящего Уведомления не является убеждение Клиента отказаться от
                    совершения Операций на финансовых рынках. Изложенная в Уведомлении информация
                    должна помочь Клиенту идентифицировать, проанализировать, оценить возможные
                    риски и принять взвешенные решения для минимизации потерь, которые могут
                    возникнуть при совершении Операций.
                </p>
            </div>
        </Layout>
    );
};
