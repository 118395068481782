import { Header } from '../../components/header/Header';
// import { HeaderVideo } from '../../components/headerVideo/HeaderVideo';
import { Education } from '../../components/homePage/education/Education';
// import { Hero } from '../../components/homePage/hero/Hero';
// import { Products } from '../../components/homePage/products/Products';
import { Services } from '../../components/homePage/services/Services';
import SliderComponent from '../../components/homePage/slider/Slider';
import { Layout } from '../../shared/layout/Layout';
import cls from './HomePage.module.scss';

export const HomePage = () => {
    return (
        <>
            {/* <HeaderVideo /> */}
            <Header />
            <Layout>
                <div className={cls.HomePage}>
                    {/* <Hero /> */}
                    {/* <Products /> */}
                    <SliderComponent />
                    <Education />
                    <Services />
                </div>
            </Layout>
        </>
    );
};
