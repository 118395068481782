import { useEffect } from 'react';
import { Layout } from '../../shared/layout/Layout';
import cls from './parthner.module.scss';

export const Parthner = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    return (
        <Layout>
            <div className={cls.parthner}>
                <h2 className={cls.parthner__title}>
                    Индивидуальные (специальные) условия соглашения об участии в партнерской
                    программе
                </h2>
                <p className={cls.parthner__text}>
                    Настоящий документ определяет индивидуальные (специальные) условия по сравнению
                    с Общими условиями (Регламентом) Соглашения об участии в партнерской программе,
                    размещенными в глобальной компьютерной сети Интернет на сайте Open FX
                    Investments LLC (далее – Компания) по адресу{' '}
                    <a href="https://kvant.shop">https://kvant.shop</a>, для применения в отношениях
                    между Компанией и ООО «Квант Шоп» (далее – Главный Партнер), а также между
                    Компанией и лицами, привлекаемыми Главным Партнером для участия в Партнерской
                    программе (далее – Партнеры).
                </p>
                <p className={cls.parthner__text}>
                    Все термины и определения, используемые в настоящем документе, применяются в их
                    значениях, указанных в Общих условиях (Регламенте) Соглашения об участии в
                    партнерской программе (далее – Регламент).{' '}
                </p>
                <p className={cls.parthner__text}>
                    Компания и Главный Партнер, в целях определения индивидуальных (специальных)
                    условий по сравнению с положениями Регламента, договорились о нижеследующем:
                </p>
                <p className={cls.parthner__text}>
                    1. В дополнение к типам партнерских программ, указанным в пункте 4.1 Регламента,
                    возможно участие в Партнерской программе по типу «Трафик» (CPA) и (или) «ВИП»
                    (VIP)
                </p>
                <p className={cls.parthner__text}>
                    2. Условия Партнерской программы по типу «Трафик» (CPA)
                    <p className={cls.parthner__text}>
                        2.1. Партнерская программа по типу «Трафик» (CPA) возможна для Партнеров,
                        привлекающих 5 и более Новых Клиентов в течение каждого календарного месяца
                    </p>
                    <p className={cls.parthner__text}>
                        2.2. Новым Клиентом, привлеченным Партнером по типу Партнерской программы
                        «Трафик» (CPA), будет считаться лицо, которое помимо соблюдения общих
                        требований Регламента к Новым Клиентам, будет удовлетворять одновременно
                        всем следующим дополнительным условиям:
                        <p className={cls.parthner__text}>
                            1. внесение депозита (маржинального обеспечения) в сумме, достаточной
                            для открытия реального аккаунта в Компании (Форекс-компании) и
                            использования сервисов Главного Партнера;
                        </p>
                        <p className={cls.parthner__text}>
                            2. использование сервисов Главного Партнера;
                        </p>
                        <p className={cls.parthner__text}>
                            3. отсутствие вывода средств (в том числе частичного) в течение срока,
                            равного 30 (тридцати) дням с момента внесения средств в сумме,
                            определенной в подпункте 1) настоящего подпункта;
                        </p>
                        <p className={cls.parthner__text}>
                            4. совершение операций на реальном аккаунте в объеме не менее 1
                            расчетного лота (здесь и далее по тексту 1 расчетный лот равен 100 000
                            долларов США) на каждую 1000 долларов США (или эквивалента данной суммы
                            в иной валюте) внесенных средств в качестве депозита (маржинального
                            обеспечения).
                        </p>
                    </p>
                    <p className={cls.parthner__text}>
                        2.3. Размер выплачиваемого вознаграждения Партнеру, участвующему в
                        Партнерской программе по типу «Трафик» (CPA), состоит из двух частей:
                        <p className={cls.parthner__text}>- фиксированная сумма</p>
                        <p className={cls.parthner__text}>
                            - доля (в процентах) от комиссионного дохода Компании
                        </p>
                    </p>
                    <p className={cls.parthner__text}>
                        2.4. Размер фиксированной суммы вознаграждения Партнера составляет:
                        <p className={cls.parthner__text}>
                            - 50 долларов США за каждого Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 2.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет от 500 (включительно) до 1000 долларов США (или эквивалента
                            данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            - 100 долларов США за каждого Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 2.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет от 1000 (включительно) до 5000 долларов США (или эквивалента
                            данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            - 200 долларов США за каждого Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 2.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет не менее 5000 долларов США (или эквивалента данной суммы в
                            иной валюте).
                        </p>
                    </p>
                    <p className={cls.parthner__text}>
                        2.5. Доход Компании в виде комиссионных по сделке клиента для целей расчета
                        вознаграждения Партнера признается равным 0,01% от объема сделки клиента вне
                        зависимости от типа аккаунта клиента.
                    </p>
                    {/* <p className={cls.parthner__text}>
                        2.6. Партнер, участвующий в Партнерской программе по типу «Трафик» (CPA),
                        получает долю (в процентах) от комиссионного дохода Компании по сделкам,
                        совершаемым Новыми Клиентами, привлеченными Партнером, в следующих размерах
                        согласно Таблице 1:
                        <p className={cls.parthner__text}>(таблица 1)</p>
                    </p> */}
                    <p className={cls.parthner__text}>
                        2.6. При невыполнении в отчетном периоде (календарный месяц) условия,
                        указанного в подпункте 2.1 настоящего пункта, Компания вправе не начислять
                        вознаграждение Партнеру, участвующему в Партнерской программе по типу
                        «Трафик» (CPA), в части фиксированной суммы. В этом случае за Новых
                        Клиентов, привлеченных Партнером в данном отчетном периоде, выплачивается
                        вознаграждение только в части доли (в процентах) от комиссионного дохода
                        Компании.
                    </p>
                </p>
                <p className={cls.parthner__text}>
                    3. Условия Партнерской программы по типу «ВИП» (VIP).
                    <p className={cls.parthner__text}>
                        3.1. Партнерская программа по типу «ВИП» (VIP) предназначена для
                        сотрудничества с Партнерами, привлекающими Новых Клиентов с размерами
                        первоначальных депозитов (суммой внесенного маржинального обеспечения) не
                        менее 20 000 долларов США (или эквивалента данной суммы в иной валюте).
                    </p>
                    <p className={cls.parthner__text}>
                        3.2. Новым Клиентом, привлеченным по типу Партнерской программы «ВИП» (VIP),
                        будет считаться лицо, которое помимо соблюдения общих требований Регламента
                        к Новым Клиентам, будет удовлетворять одновременно всем следующим
                        дополнительным условиям:
                        <p className={cls.parthner__text}>
                            1. внесение депозита (маржинального обеспечения) в сумме не менее 20 000
                            долларов США (или эквивалента данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            2. отсутствие вывода средств (в том числе частичного) в течение срока,
                            равного 60 (шестидесяти) дням с момента внесения средств в сумме,
                            определенной в подпункте 1) настоящего подпункта;
                        </p>
                        {/* <p className={cls.parthner__text}>
                            3. совершение операций на реальном аккаунте в объеме не менее,
                            указанного ниже в Таблице 2.
                            <p className={cls.parthner__text}>(таблица 2)</p>
                        </p> */}
                    </p>
                    <p className={cls.parthner__text}>
                        3.3. Размер выплачиваемого вознаграждения Партнеру, участвующему в
                        Партнерской программе по типу «ВИП» (VIP), состоит из двух частей:
                        фиксированная сумма и доля (в процентах) от комиссионного дохода Компании.
                    </p>
                    <p className={cls.parthner__text}>
                        3.4. Размер фиксированной суммы вознаграждения Партнера составляет:
                        <p className={cls.parthner__text}>
                            - 2,5% от суммы первоначально внесенных средств в качестве депозита
                            (маржинального обеспечения) Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 3.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет от 20 000 (включительно) до 50 000 долларов США (или
                            эквивалента данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            - 3,0% от суммы первоначально внесенных средств в качестве депозита
                            (маржинального обеспечения) Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 3.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет от 50 000 (включительно) до 100 000 долларов США (или
                            эквивалента данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            - 3,5% от суммы первоначально внесенных средств в качестве депозита
                            (маржинального обеспечения) Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 3.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет от 100 000 (включительно) до 250 000 долларов США (или
                            эквивалента данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            - 4,0% от суммы первоначально внесенных средств в качестве депозита
                            (маржинального обеспечения) Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 3.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет от 250 000 (включительно) до 500 000 долларов США (или
                            эквивалента данной суммы в иной валюте);
                        </p>
                        <p className={cls.parthner__text}>
                            - 5,0% от суммы первоначально внесенных средств в качестве депозита
                            (маржинального обеспечения) Нового Клиента, удовлетворяющего условиям,
                            указанным в подпункте 3.2 настоящего пункта, если размер первоначально
                            внесенных средств в качестве депозита (маржинального обеспечения)
                            составляет не менее 500 000 долларов США (или эквивалента данной суммы в
                            иной валюте).
                        </p>
                    </p>
                    <p className={cls.parthner__text}>
                        3.5. Доход Компании в виде комиссионных по сделке клиента для целей расчета
                        вознаграждения Партнера признается равным 0,01% от объема сделки клиента вне
                        зависимости от типа аккаунта клиента
                    </p>
                    <p className={cls.parthner__text}>
                        3.6. Партнер, участвующий в Партнерской программе по типу «ВИП» (VIP),
                        получает партнерское вознаграждение в размере 50% от комиссионного дохода
                        Компании по сделкам, совершаемым Новыми Клиентами, привлеченными Партнером.
                    </p>
                    <p className={cls.parthner__text}>
                        3.7. Выплата вознаграждения Партнеру в виде фиксированной суммы
                        осуществляется в несколько этапов пропорционально выполнению Новыми
                        Клиентами требования о совершении минимального объема операций на реальном
                        аккаунте.
                        {/* согласно Таблице 2. */}
                    </p>
                    <p className={cls.parthner__text}>
                        3.8. Компания вправе не начислять вознаграждение Партнеру, участвующему в
                        Партнерской программе по типу «ВИП» (VIP), при не соответствии Нового
                        Клиента условию, указанному в подпункте 3.1 настоящего пункта.
                    </p>
                </p>
                <p className={cls.parthner__text}>
                    4. Компания вправе не начислять вознаграждение Партнеру, участвующему в
                    Партнерской программе по типу «Трафик» (CPA), если в отношении одного или
                    нескольких Новых Клиентов, привлеченных данным Партнером будет идентифицирована
                    «симуляция» совершения операций и (или) использования сервисов Главного
                    Партнера, под которой, в частности, понимаются следующие действия:
                    <p className={cls.parthner__text}>
                        - отсутствие непрерывного использования сервисов Главного Партнера в течение
                        минимального срока, равного 15 дням;
                    </p>
                    <p className={cls.parthner__text}>
                        - периодическое отключение от сервисов Главного Партнера и затем повторное
                        подключение с целью уменьшения количества совершаемых сделок;
                    </p>
                    <p className={cls.parthner__text}>
                        - совершение сделок в количестве меньшем, чем средний показатель по
                        остальным клиентам, использующим данный сервис, на 50% и более;
                    </p>
                    <p className={cls.parthner__text}>
                        - совершение большого количества сделок, по которым время между открытием и
                        закрытием составляет не более 5 минут;
                    </p>
                    <p className={cls.parthner__text}>
                        - совершение иных действий, которые, по мнению Компании, являются
                        подозрительными и не соответствуют обычному поведению лиц, совершающих
                        операции на финансовых рынках.
                    </p>
                    <p className={cls.parthner__text}>
                        В данном случае Компания вправе не начислять Партнеру вознаграждение как в
                        отношении конкретных лиц, привлеченных Партнером и уличенных в «симуляции»,
                        так и в отношении всех Новых Клиентов, привлеченных данным Партнером, а
                        также заблокировать Партнера. Если Компания принимает решение о блокировке
                        Партнера, последующее его участие в Партнерской программе Компании по
                        данному типу или по иным типам не допускается.
                    </p>
                </p>
                <p className={cls.parthner__text}>
                    5. Настоящие индивидуальные (специальные) условия Соглашения об участии в
                    Партнерской программе вступают в силу с 1 января 2024 года.
                </p>
            </div>
        </Layout>
    );
};
