import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter, RouterProvider } from 'react-router-dom';

import './styles/index.css';
import { router } from './routes/Routes';
import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter><App /></BrowserRouter>);
