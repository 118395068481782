import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { Layout } from './shared/layout/Layout';
import { BackHome } from './components/backHome/BackHome';
import { HomePage } from './pages/homePage/HomePage';
import { Risk } from './pages/risk/Risk';
import { Oferta } from './pages/oferta/Oferta';
import { Parthner } from './pages/parthner/Parthner';
import { Politics } from './pages/politics/Politics';

export const App = () => {
    const { pathname } = useLocation();

    return (
        <>
            {pathname !== '/' && <BackHome />}
            {/* <Header /> */}
            {/* <Layout> */}
            {/* <Outlet /> */}
            {/* </Layout> */}
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/politics" element={<Politics />} />
                <Route path="/risk" element={<Risk />} />
                <Route path="/oferta" element={<Oferta />} />
                <Route path="/parthner" element={<Parthner />} />
            </Routes>
            <Footer />
        </>
    );
};
